import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/portfolio.js";
import { graphql } from "gatsby";
export const query = graphql`
  query {
    sony1: file(relativePath: { eq: "Sony/sony-01.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sony2: file(relativePath: { eq: "Sony/sony-02.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sony3: file(relativePath: { eq: "Sony/sony-03.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sony4: file(relativePath: { eq: "Sony/sony-04.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sony5: file(relativePath: { eq: "Sony/sony-05.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sony6: file(relativePath: { eq: "Sony/sony-06.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sony7: file(relativePath: { eq: "Sony/sony-07.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sony8: file(relativePath: { eq: "Sony/sony-08.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sony9: file(relativePath: { eq: "Sony/sony-09.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sony10: file(relativePath: { eq: "Sony/sony-10.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridTwo = makeShortcode("GridTwo");
const ImageContainer = makeShortcode("ImageContainer");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <GridTwo mdxType="GridTwo">
      <ImageContainer classes="large" fluid={props.data.sony1.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.sony2.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The Sony Design System did not rely on rigid page templates. Instead, we created a flexible system of modules that could easily be rearranged and customized, per page. Our system consisted of a carefully designed library of interaction patterns and content layouts, that could seamlessly stack and rearrange on a page.</p>
      <ImageContainer classes="small" fluid={props.data.sony3.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.sony4.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.sony5.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The module system is extensive and included every imaginable variation.</p>
      <ImageContainer classes="small right" fluid={props.data.sony7.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="large" fluid={props.data.sony6.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">One key aspect of the Sony design system was to support multi-screen browsing experiences. The challenge in this task was strategically designing system level flexibility into components and modules that could react to devices and screen sizes. It was just as important to support a viewing experience on touch devices as it was on the desktop.</p>
      <ImageContainer classes="small" fluid={props.data.sony8.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.sony9.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">Design direction for Sony meant appealing to the cultures and needs of the entire globe. Our web product served 110 different countries and was translated into 30 languages. We strove for universal appeal with design, experience, and content while customizing per locale as required.</p>
      <ImageContainer classes="large" fluid={props.data.sony10.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small right blog-text">Please contact me if you'd like to learn more about this project.</p>
    </GridTwo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      